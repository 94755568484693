/* Component Dependencies */
var logosPortfolioTemplate = require('templates/logosPortfolio.hbs');
var AriesComponent = require('libs/aries-component');

AriesComponent.create({
  type: 'logosPortfolio',
  template: {
    'logosPortfolio': logosPortfolioTemplate
  }
});
